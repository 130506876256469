import React from 'react'
import styles from './VideoPlace.module.css'
import star from '../img/stargrey.svg'
import arrow from '../img/arrowplace.svg'
import VKApi from '../API/VKApi/VKApi'

export default function VideoPlace() {
  return (
    <>
      <div className={styles.background}>
        <div className={`${styles.star} ${styles.bigStar}`}>
          <img src={star} alt="star" />
        </div>
        <div className={`${styles.star} ${styles.starOne} `}>
          <img src={star} alt="star" />
        </div>
        <div className={`${styles.star} ${styles.starTwo}`}>
          <img src={star} alt="star" />
        </div>
        <div className={`${styles.arrow}`}>
          <img src={arrow} alt="star" />
        </div>
        <div className={styles.container}>
          <div className={styles.topContainer}>
            <div>
              <h1 className={styles.desktop}>
                Лучше всего о нас расскажет музыка
              </h1>
              <h1 className={styles.mobile}>
                Лучше всего о нас
                <br />
                расскажет музыка
              </h1>
            </div>
            <div className={styles.vkapicontainer}>
              <VKApi />
            </div>
          </div>
          <div className={styles.botContainer}>
            <div>
              <p>приходи и услышь все сам</p>
            </div>
            <div>
              <p>МЫ ЖДЕМ ИМЕННО ТЕБЯ</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
