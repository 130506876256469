import React, { useState, useEffect } from 'react'
import './Navbar.css'
import Logo from '../img/logo.svg'
import LogoBG from '../img/logobg.svg'
import close from '../img/close.svg'
import starBG from '../img/starBG.svg'
import { Link, useLocation } from 'react-router-dom'

function Navbar() {
  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const location = useLocation()

  const handleBurgerMenuClick = () => {
    setBurgerMenuOpen(!isBurgerMenuOpen)
  }
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <nav className={`nav ${isFixed ? 'fixed-nav' : ''}`}>
      <div className="navContainer">
        <Link to="/" className="siteTitle">
          <img src={Logo} alt="sitelogo" />
        </Link>
        <div className={`burger-menu`} onClick={handleBurgerMenuClick}></div>
        <div className={`burger-container ${isBurgerMenuOpen ? 'open' : ''}`}>
          <div className="burger-menu-upper">
            <img src={LogoBG} alt="sitelogo" className="logoBG" />
            <img
              className="close-burger"
              src={close}
              alt="close"
              onClick={handleBurgerMenuClick}
            />
          </div>
          <ul>
            <div className="burger-link">
              <li
                className={`star-icon ${
                  location.pathname === '/studio' ? 'actived' : ''
                }`}
              >
                <img src={starBG} alt="starBG" />
              </li>
              <CustomLink to="/studio">СТУДИЯ</CustomLink>
            </div>
            <div className="burger-link">
              <li
                className={`star-icon ${
                  location.pathname === '/store' ? 'actived' : ''
                }`}
              >
                <img src={starBG} alt="starBG" />
              </li>
              {/* <CustomLink to="/store">МАГАЗИН БИТОВ</CustomLink> */}
            </div>
          </ul>
        </div>

        {/* <ul>
          <CustomLink to="/studio">СТУДИЯ</CustomLink>
          <CustomLink to="/store">МАГАЗИН БИТОВ</CustomLink>
        </ul> */}
      </div>
    </nav>
  )
}

function CustomLink({ to, children, ...props }) {
  const { pathname } = useLocation()

  const isActive = pathname === to

  return (
    <li className={isActive ? 'active' : ''}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  )
}

export default Navbar
