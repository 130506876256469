import React, { useState, useEffect, useContext } from 'react'
import message from '../img/Message.svg'
import { FaTimes } from 'react-icons/fa'
import './LinkButton.css'
import tg from '../img/tg.png'
import vk from '../img/vk.png'
import inst from '../img/inst.png'
import phone from '../img/phone.png'
import starup from '../img/starup.svg'
import starleft from '../img/starleft.svg'
import starright from '../img/starright.svg'
import starmid from '../img/star.svg'
import starbot from '../img/starbot.svg'
import starleftbot from '../img/starleftbot.svg'

import Form from '../form/Form.tsx'
import NotificationContext from '../../context/NotificationContext.js'
import Notification from '../notification/Notification.js'

export default function LinkButton() {
  const [isModalOpen, setModalOpen] = useState(false)
  const [showButton, setShowButton] = useState(true)
  const [showNotification, setShowNotification] = useState(false)

  const [show, setShow] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      const isAtBottom =
        scrollY + window.innerHeight >= document.documentElement.scrollHeight

      setShow(!isAtBottom)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const openModal = () => {
    setModalOpen(true)
    setShowButton(false)
  }

  const closeModal = () => {
    setModalOpen(false)
    setShowButton(true)
  }

  const handleModalClick = (event) => {
    event.stopPropagation()
  }

  const handleShowNotification = (value) => {
    setShowNotification(value)
  }

  const { notifications } = useContext(NotificationContext)

  return (
    <>
      {showButton && (
        <button
          className={`fixed-button ${showButton ? 'show' : ''}`}
          onClick={openModal}
        >
          <img src={message} alt="message" />
        </button>
      )}

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal" onClick={handleModalClick}>
            <FaTimes className="close-btn" onClick={closeModal} />
            <img src={starup} className="star-background" alt="star"></img>
            <img src={starleft} className="star-background" alt="star"></img>
            <img src={starright} className="star-background" alt="star"></img>
            <img src={starmid} className="star-background" alt="star"></img>
            <img src={starbot} className="star-background" alt="star"></img>
            <img src={starleftbot} className="star-background" alt="star"></img>
            <div className="modal-content">
              <p>ЗАПИСАТЬСЯ НА КОНСУЛЬТАЦИЮ</p>
              <p>
                <span>Оставь</span> свою заявку сейчас и получи{' '}
                <span>скидку</span> 10 %
              </p>
              <div className="inputBlock">
                <Form setShowNotification={handleShowNotification} />
              </div>
              <p>
                Остались вопросы? Пиши нам в соц. сетях или набирай по номеру
              </p>
              <div className="social-block">
                <a
                  href="https://www.instagram.com/gsmedia_rec/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={inst} alt="Инстаграм"></img>
                </a>
                <a href="https://vk.com/gsrec" target="_blank" rel="noreferrer">
                  <img src={vk} alt="ВКонтакте"></img>
                </a>
                <a
                  href="https://t.me/gsrecpro"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={tg} alt="Телеграмм"></img>
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <img src={phone} alt="Телефон"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {showNotification && (
        <Notification text="Ваша заявка отправлена" status="success" />
      )}
    </>
  )
}
