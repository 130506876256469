export const trackSets = [
  {
    name: 'mode1',
    genre: 'POP',
    tracks: [],
  },
  {
    name: 'mode2',
    genre: 'RAP',
    tracks: [],
  },
  {
    name: 'mode3',
    genre: 'TYPE BEATS',
    tracks: [],
  },
  {
    name: 'mode4',
    genre: 'UK GARAGE',
    tracks: [],
  },
  {
    name: 'mode5',
    genre: 'UKULELE',
    tracks: [],
  },
  {
    name: 'mode6',
    genre: 'ЭЛЕКТРО',
    tracks: [],
  },
  {
    name: 'example1',
    genre: 'MIXING',
    tracks: [
      {
        artist: 'MIXING',
        title: 'ИНДИ 1',
        src: require('./music/EXAMPLES/MIXING/ИНДИ_1.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ИНДИ 2',
        src: require('./music/EXAMPLES/MIXING/ИНДИ_2.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ИНДИ 3',
        src: require('./music/EXAMPLES/MIXING/ИНДИ_3.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ПОП РОК',
        src: require('./music/EXAMPLES/MIXING/ПОП РОК.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ППОП РОК 2',
        src: require('./music/EXAMPLES/MIXING/ПОП РОК_2.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ПОП',
        src: require('./music/EXAMPLES/MIXING/ПОП.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ПОП 2',
        src: require('./music/EXAMPLES/MIXING/ПОП_2.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'ПОП 3',
        src: require('./music/EXAMPLES/MIXING/ПОП_3.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'РОК',
        src: require('./music/EXAMPLES/MIXING/РОК.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'РОК 2',
        src: require('./music/EXAMPLES/MIXING/РОК_2.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'РЭП',
        src: require('./music/EXAMPLES/MIXING/РЭП.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'РЭП 2',
        src: require('./music/EXAMPLES/MIXING/РЭП_2.mp3'),
      },
      {
        artist: 'MIXING',
        title: 'РЭП 3',
        src: require('./music/EXAMPLES/MIXING/РЭП_3.mp3'),
      },
    ],
  },
  {
    name: 'example2',
    genre: 'АРАНЖИРОВКИ',
    tracks: [
      {
        title: 'Arrange',
        artist: 'POP',
        src: require('./music/EXAMPLES/arrangements/POP.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'R&B DARK POP',
        src: require('./music/EXAMPLES/arrangements/R&B DARK POP.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'RAP',
        src: require('./music/EXAMPLES/arrangements/RAP.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'RAP 2',
        src: require('./music/EXAMPLES/arrangements/RAP_2.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'RAP 3',
        src: require('./music/EXAMPLES/arrangements/RAP_3.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'ROCK',
        src: require('./music/EXAMPLES/arrangements/ROCK.mp3'),
      },
      {
        title: 'Arrange',
        artist: 'ROCK 2',
        src: require('./music/EXAMPLES/arrangements/ROCK_2.mp3'),
      },
    ],
  },
  {
    name: 'example3',
    genre: 'ДЕМО',
    tracks: [
      {
        title: 'my eyes on you',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/my eyes on you (full prod).mp3'),
      },
      {
        title: 'shtrobov11',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov11 (rock demo arange).mp3'),
      },
      {
        title: 'shtrobov2',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov2 (rock demo arange) .mp3'),
      },
      {
        title: 'shtrobov3',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov3 (rock demo arange).mp3'),
      },
      {
        title: 'shtrobov53',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov53 (rock demo arange).mp3'),
      },
      {
        title: 'shtrobov67',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov67 (rock demo arange).mp3'),
      },
      {
        title: 'shtrobov68',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov68 (rock demo arange).mp3'),
      },
      {
        title: 'shtrobov8 temp 138',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/shtrobov8 (rock demo arange).mp3'),
      },
      {
        title: 'Type Creed',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Type Creed (demo).mp3'),
      },
      {
        title: 'Вспоминай',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Вспоминай (full prod).mp3'),
      },
      {
        title: 'Губы',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Губы (full prod).mp3'),
      },
      {
        title: 'Не отпускай',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Не отпускай (demo).mp3'),
      },
      {
        title: 'Потому что',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Потому что (full prod).mp3'),
      },
      {
        title: 'Темные Вороны',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Темные вороны (demo).mp3'),
      },
      {
        title: 'Эй Бэйби',
        artist: 'ДЕМО',
        src: require('./music/EXAMPLES/DEMO($$$)/Эй, бейби (demo).mp3'),
      },
    ],
  },
  {
    name: 'example4',
    genre: 'БИЗНЕС',
    tracks: [
      {
        title: 'IVR',
        artist: 'БИЗНЕС',
        src: require('./music/EXAMPLES/Business/IVR.mp3'),
      },
      {
        title: 'Форум',
        artist: 'БИЗНЕС',
        src: require('./music/EXAMPLES/Business/Гимн молодежного форумафорума.mp3'),
      },
      {
        title: 'Фильм',
        artist: 'БИЗНЕС',
        src: require('./music/EXAMPLES/Business/Музыка для фильма [DEMO].mp3'),
      },
      {
        title: 'ПроБьюти',
        artist: 'БИЗНЕС',
        src: require('./music/EXAMPLES/Business/ПроБьюти[Джингл под ключ].mp3'),
      },
    ],
  },
]

trackSets.forEach((set) => {
  set.tracks.sort((a, b) => {
    const nameA = a.title.toUpperCase()
    const nameB = b.title.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
})
