import React, { FC, useState, useContext } from 'react'

import {
  MantineProvider,
  TextInput,
  Button,
  Group,
  Box,
  Textarea,
  InputBase,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { sendMessage } from '../../api/telegram.ts'
import { IMaskInput } from 'react-imask'

import './Form.css'
import NotificationContext from '../../context/NotificationContext.js'

interface FormProps {
  setShowNotification: (value: boolean) => void
}

export const Form: FC<FormProps> = ({ setShowNotification }) => {
  const [isLoading, setIsLoading] = useState(false)
  const { addNotification } = useContext(NotificationContext)

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      text: '',
    },

    validate: {
      name: (value) => (value.length < 1 ? 'Поле не может быть пустым' : null),
      phone: (value) =>
        value.length < 17 ? 'Не правильно набран номер' : null,
      text: (value) => (value.length < 1 ? 'Поле не может быть пустым' : null),
    },
  })

  const handleSubmit = async ({
    name,
    phone,
    text,
  }: typeof form.values): Promise<void> => {
    try {
      setIsLoading(true)

      const message = `
      Имя: ${name}, Телефон: ${phone}, Текст: ${text}`

      await sendMessage(message)
      setShowNotification(true)
    } catch (e) {
      form.setFieldError('email', e as string)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MantineProvider>
      <Box>
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Имя"
            placeholder="Введите имя"
            {...form.getInputProps('name')}
          />

          <InputBase
            label="Номер телефона"
            component={IMaskInput}
            mask="+7 (000) 000-0000"
            placeholder="Введите номер телефона"
            {...form.getInputProps('phone')}
          />

          <Textarea
            label="Цель"
            placeholder="Опишите чем вы занимайтесь, с какой целью хотите попасть к нам на консультацию"
            {...form.getInputProps('text')}
          />

          <Group justify="flex-end" mt="md">
            <Button loading={isLoading} type="submit" onClick={addNotification}>
              ЗАПИСАТЬСЯ
            </Button>
          </Group>
        </form>
      </Box>
    </MantineProvider>
  )
}

export default Form
