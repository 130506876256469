import styles from './Uslugi.module.css'
import star from '../img/starsUslugi.svg'
import PricingBox from '../uslugiBox/pricingBox'

function Uslugi() {
  return (
    <div className={styles.uslugiContainer}>
      <div className={styles.background}>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <p className={`${styles.textUslugi} ${styles.desktop}`}>УСЛУГИ</p>
          </div>
          <PricingBox />
          <div className={styles.textBlock}>
            <img className={styles.stars} src={star} alt="stars" />
          </div>
          <div className={`${styles.textUslugi} ${styles.creditsBlock}`}>
            <div>
              <p className={styles.title}>CREDITS</p>
              <span className={styles.blackBox}></span>
            </div>
            <div className={styles.credits}>
              <p className={styles.creditsText}>
                Anna Creamsoda, Aarne, Кисло-Сладкий, Bandura, Lizer, интакто,
                <br />
                сны саламандры, ADA, Illuminate Music Group, Яндекс, Audi, H&M,
                Dodo Pizza
              </p>
              <span className={styles.blackBox}></span>
              <span className={styles.blackBox}></span>
              <span className={styles.blackBox}></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Uslugi
