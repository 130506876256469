import React from 'react'
import './pricingBox.css'
import star from '../img/star.svg' // The CSS file with the styles

const services = [
  {
    name: 'Запись звука',
    price: '1500',
    sum: '₽',
    time: '/час',
    star,
    description:
      'Запись вокала и музыкальных инструментов в профессиональной студии. Помощь при записи со стороны звукорежиссера.',
  },
  {
    name: 'Аранжировка',
    price: '8000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Написание музыкальных аранжировок по вашему техническому заданию.',
  },
  {
    name: 'Сведение/Финализация',
    price: '10000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Обработка звуковых дорожек для создания качественного звучания. Устранение недочетов, правка нот, работа с динамикой и окрасом песни.',
  },
  {
    name: 'Песня под ключ',
    price: '25000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Создание музыкальных произведений на основе ваших предпочтений и хотелок. В услугу входят: придумывание идеи, написание аранжировки и текста, запись, сведение и мастеринг. ',
  },
  {
    name: 'Продвижение',
    price: '15000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Финальная обработка микса. Работа с громкостью, динамикой и характером. ',
  },
  {
    name: 'Экспресс сведение',
    price: '4000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Сведение песни/демки в короткие сроки ограниченным набором инструментов на основе видения звукорежиссера. ',
  },
  {
    name: 'Текст для песни',
    price: '5000',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Написание/редактирование текста по вашему техническому заданию. Помощь с рифмами, смыслом и выражением нужной эмоции.',
  },
  {
    name: 'Аренда студии',
    price: '1200',
    any: 'от',
    sum: '₽',
    star,
    description:
      'Возможность арендовать студию для работы над звуком своей командой.',
  },
]

const Service = ({ name, price, any, sum, star, time, description }) => (
  <div className="service">
    <div className="service-inner">
      <div className="service-front">
        <h3>{name}</h3>
        <div className="price-block">
          <img className="star" src={star} alt="star" />
          <p className="price any">{any}</p>
          <p className="price">{price}</p>
          <p className="price sum">{sum}</p>
          <p className="price any">{time}</p>
        </div>
      </div>
      <div className="service-back">
        <h3 className="description">{description}</h3>
      </div>
    </div>
  </div>
)

const PricingBox = () => (
  <div className="pricing-table">
    {services.map((service, index) => (
      <Service
        star={service.star}
        key={index}
        any={service.any}
        name={service.name}
        price={service.price}
        sum={service.sum}
        time={service.time}
        description={service.description}
      />
    ))}
  </div>
)

export default PricingBox
