import style from './Feedback.module.css'
import inst from '../img/inst.svg'
import vk from '../img/vk.svg'
import tg from '../img/tg.svg'
import logo2 from '../img/logo_2.svg'
import logo2Grey from '../img/logo2Grey.svg'
import decor from '../img/textDecor.png'
import decor2 from '../img/textDecorOpacity.png'
import marker from '../img/marker.svg'
import star from '../img/bigRedStar.svg'
import starMobile from '../img/starMobile.svg'

function Feedback({ currentPage }) {
  const feedbackClass =
    currentPage === 'store' ? `${style.feedbackRed}` : `${style.feedbackGray}`

  return (
    <footer className={`${style.footer} ${feedbackClass}`}>
      <div className={style.footerContainer}>
        <img
          className={`${style.footerStar} ${style.desktop}`}
          src={star}
          alt="star"
        />
        <img
          className={`${style.footerStar} ${style.mobile}`}
          src={starMobile}
          alt="star"
        />
        <div className={style.footerContainerHead}>
          <div className={style.footerLogoBlock}>
            <img className={style.logoGreyBG} src={logo2} alt="logo" />
            <img className={style.logoRedBG} src={logo2Grey} alt="logo" />
          </div>
          <div className={style.footerTextBlock}>
            <h1>ОСТАЛИСЬ ВОПРОСЫ?</h1>
            <p className={style.footerTextMobile}>НАШИ КОНТАКТНЫЕ ДАННЫЕ</p>
          </div>
        </div>
        <div className={style.footerContainerMid}>
          <img
            className={`${style.textDecor} ${style.decorFirst}`}
            src={decor}
            alt="decor"
          />
          <img
            className={`${style.textDecor} ${style.decorSecond}`}
            src={decor2}
            alt="decor"
          />
          <div className={style.footerTextBlock}>
            <p className={style.footerAboutText}>
              GS Rec - музыкальная база, студия звукозаписи и музыкальный лейбл.
            </p>
            <br />
            <br />

            <p className={style.footerAboutText}>
              Место, где каждый может как «поиграть» в музыку, так и полностью
              погрузиться во все этапы создания музыкальных композиций.
            </p>
            <br />
            <br />

            <p className={style.footerAboutText}>
              Наша команда поможет построить надёжный фундамент вашей
              музыкальной идеи и воплотить в жизнь то, что звучит у вас в
              голове.
            </p>
          </div>
        </div>
        <div className={style.footerContainerBottom}>
          <div>
            <p>приходи на консультацию по адресу</p>
          </div>
          <div className={style.footerBotText}>
            <p>
              напиши
              <br />
              нам в соц. сетях
            </p>
          </div>
        </div>
        <div className={style.footerContacts}>
          <div className={style.footerConteiner}>
            <div className={style.footerStreetContainer}>
              <img src={marker} alt="marker" />
              <p className={style.footerStreet}>
                г. Пермь,
                <br /> ул. Чкалова 7 к 1
              </p>
            </div>
            <div className={style.socialsBlock}>
              <a target="blank" href="https://www.instagram.com/gsmedia_rec/">
                <img src={inst} alt="inst" />
              </a>
              <a target="blank" href="https://vk.com/goldstar_rec">
                <img src={vk} alt="vk" />{' '}
              </a>
              <a target="blank" href="https://t.me/gsrecpro">
                <img src={tg} alt="tg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Feedback
