import WebFont from 'webfontloader'
import './App.css'
// import Home from './components/pages/Home'
import Studio from './components/pages/Studio'
import { Route, Routes } from 'react-router-dom'
import useNotifications from './hooks/useNotification'
import NotificationContext from './context/NotificationContext'

WebFont.load({
  google: {
    families: ['Bebas Neue'],
  },
  custom: {
    families: ['YourCustomFont'],
    urls: ['/path/to/your/font.css'],
  },
  active: function () {},
  inactive: function () {},
})

function App() {
  const { notifications, addNotification } = useNotifications()

  return (
    <>
      <NotificationContext.Provider
        value={{
          notifications,
          addNotification,
        }}
      >
        <Routes>
          <Route path="/" element={<Studio />} />
        </Routes>
      </NotificationContext.Provider>
    </>
  )
}

export default App
