import React, { useEffect } from 'react'
import './VKApi.css'

const VKApi = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://vk.com/js/api/openapi.js?168'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      if (window.VK && window.VK.Widgets) {
        window.VK.Widgets.Group(
          'vk_groups',
          {
            mode: 2,
            wide: 1,
            height: 500,
            width: 'auto',
            no_cover: 0,
            color1: 'FFFFFF',
            color2: '000000',
            color3: '5181B8',
          },
          202108289
        )
      }
    }

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <div
      className="vkcontainer"
      id="vk_groups"
      style={{
        width: '100%', // Устанавливаем ширину виджета на 100% чтобы сделать его адаптивным
      }}
    ></div>
  )
}

export default VKApi
