import React, { useState, useEffect } from 'react'
import style from './Change.module.css'

import MusicPlayer from '../music/MusicPlayer'
import { trackSets } from '../music/trackSets'

function Change() {
  const [visibleTrackIndex, setVisibleTrackIndex] = useState(6)
  const [showDropdown, setShowDropdown] = useState(false)
  const [initialized, setInitialized] = useState(false)
  const [resetPlayer, setResetPlayer] = useState(false)

  const currentTrackSet = trackSets[visibleTrackIndex]
  const genre = currentTrackSet.genre

  useEffect(() => {
    const handleResize = () => {
      if (initialized) {
        setShowDropdown(window.innerWidth < 1000)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    setInitialized(true)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [initialized])

  const handleTabChange = (index) => {
    setVisibleTrackIndex(index)
  }

  return (
    <div className={style.changeBackground}>
      <div className={style.changeContainer}>
        <div className={style.changeElementsText}>
          <div className={style.pageText}>
            <div className={style.titleBlock}>
              <h2 className={style.titleText}>{genre}</h2>
            </div>

            <div className={style.dropdownList}>
              <button
                onClick={() => handleTabChange(6)}
                className={`${style.dropdownButton} ${
                  visibleTrackIndex === 6 ? style.activated : ''
                }`}
              >
                СВЕДЕНИЕ
              </button>
              <button
                onClick={() => handleTabChange(7)}
                className={`${style.dropdownButton} ${
                  visibleTrackIndex === 7 ? style.activated : ''
                }`}
              >
                АРАНЖИРОВКИ
              </button>
              <button
                onClick={() => handleTabChange(8)}
                className={`${style.dropdownButton} ${
                  visibleTrackIndex === 8 ? style.activated : ''
                }`}
              >
                ДЕМО
              </button>
              <button
                onClick={() => setVisibleTrackIndex(9)}
                className={`${style.dropdownButton} ${
                  visibleTrackIndex === 9 ? style.activated : ''
                }`}
              >
                БИЗНЕС
              </button>
            </div>
            <div className={style.changeMusicBox}>
              <MusicPlayer trackSetName={currentTrackSet.name} genre={genre} />
            </div>
          </div>
        </div>
        <div className={style.changeElementsButtons}>
          <div className={style.buttonBox}>
            <div className={style.buttonBoxContainer}>
              <button
                onClick={() => handleTabChange(6)}
                className={`${style.btn} ${style.btnSucces} ${
                  visibleTrackIndex === 6 ? `${style.active}` : ''
                }`}
              >
                {visibleTrackIndex === 6}
                <span>СВЕДЕНИЕ</span>
              </button>
            </div>
            <div className={style.buttonBoxContainer}>
              <button
                onClick={() => handleTabChange(7)}
                className={`${style.btn} ${style.btnSucces} ${
                  visibleTrackIndex === 7 ? `${style.active}` : ''
                }`}
              >
                {visibleTrackIndex === 7}
                <span>АРАНЖИРОВКИ</span>
              </button>
            </div>
            <div className={style.buttonBoxContainer}>
              <button
                onClick={() => handleTabChange(8)}
                className={`${style.btn} ${style.btnSucces} ${
                  visibleTrackIndex === 8 ? `${style.active}` : ''
                }`}
              >
                {visibleTrackIndex === 8}
                <span>ДЕМО</span>
              </button>
            </div>
            <div className={style.buttonBoxContainer}>
              <button
                onClick={() => handleTabChange(9)}
                className={`${style.btn} ${style.btnSucces} ${
                  visibleTrackIndex === 9 ? `${style.active}` : ''
                }`}
              >
                {visibleTrackIndex === 9}
                <span>БИЗНЕС</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Change
