// PlayerControls.js
import React from 'react'
import styles from './PlayerControls.module.css'
import play from '../img/play.png'
import pause from '../img/pause.png'
import { FaTimes } from 'react-icons/fa'

export const PlayerControls = ({
  isPlaying,
  setIsPlaying,
  audioRef,
  onVolumeChange,
  onTimeChange,
  currentTime,
  duration,
  onClose,
}) => {
  const playTrack = () => {
    if (isPlaying) {
      audioRef.current.pause()
    } else {
      audioRef.current.play()
    }
    setIsPlaying(!isPlaying)
  }

  return (
    <div className={styles.playerControls}>
      <button onClick={playTrack} className={styles.button}>
        {isPlaying ? (
          <img src={pause} alt="Pause" />
        ) : (
          <img src={play} alt="Play" />
        )}
      </button>
      <input
        type="range"
        min="0"
        max={duration}
        value={currentTime}
        onChange={onTimeChange}
        className={styles.sliderHorizontal}
      />
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        onChange={onVolumeChange}
        className={styles.sliderVertical}
        orient="vertical"
      />
      <button onClick={onClose} className={styles.closeButton}>
        <FaTimes />
      </button>
    </div>
  )
}
