import Examples from '../examples/Examples'
import Feedback from '../feedback/Feedback'
import LinkButton from '../linkButton/LinkButton'
import Navbar from '../navbar/Navbar'
import Slideshow from '../slideshow/Slideshow'
import Uslugi from '../uslugi/Uslugi'
import VideoPlace from '../videoPlace/VideoPlace'

function Studio() {
  return (
    <>
      <LinkButton />
      <Navbar />
      <Slideshow />
      <Uslugi />
      <Examples />
      <VideoPlace />
      <Feedback currentPage="studio" />
    </>
  )
}

export default Studio
