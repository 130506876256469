import styles from './Examples.module.css'
import star from '../img/starsUslugi.svg'
import Change from '../change/Change'

function Examples() {
  return (
    <div className={styles.uslugiContainer}>
      <div className={styles.background}>
        <div className={styles.container}>
          <div>
            <p className={`${styles.textUslugi}`}>ПРИМЕРЫ РАБОТ</p>
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textBlock}>
              <img className={styles.stars} src={star} alt="stars" />
            </div>
          </div>
          <div>
            <Change />
          </div>
          {/* <p className={`${styles.textUslugi} ${styles.mobile}`}>УСЛУГИ</p> */}
        </div>
      </div>
    </div>
  )
}

export default Examples
