import React, { useState, useEffect, useRef } from 'react'
import style from './Slideshow.module.css'
import staticImage from '../img/mobile.png'

const images = [
  { lowQuality: 'png/carousel1-min.jpeg', highQuality: 'png/carousel1.jpeg' },
  { lowQuality: 'png/carousel2-min.jpeg', highQuality: 'png/carousel2.jpeg' },
  { lowQuality: 'png/carousel3-min.jpeg', highQuality: 'png/carousel3.jpeg' },
  { lowQuality: 'png/carousel4-min.jpeg', highQuality: 'png/carousel4.jpeg' },
]

const delay = 25000

function Slideshow() {
  const [index, setIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const timeoutRef = useRef(null)

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, delay)
    return () => clearInterval(timer)
  }, [])
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      resetTimeout()
    }
  }, [])

  useEffect(() => {
    if (windowWidth <= 1000) {
      return // Если ширина экрана меньше или равна 1000px, выходим из эффекта
    }
    resetTimeout()
    timeoutRef.current = setTimeout(() => {
      setIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      )
    }, delay)

    return () => {
      resetTimeout()
    }
  }, [index, windowWidth])

  if (windowWidth <= 1000) {
    return (
      <img src={staticImage} alt="Static Slide" className={style.staticImage} />
    )
  }

  return (
    <div className={style.slideshow}>
      <div
        className={style.slideshowImages}
        style={{ transform: `translateX(-${index * 100}%)` }}
      >
        {images.map(({ lowQuality, highQuality }, idx) => (
          <img
            key={idx}
            src={windowWidth <= 1000 ? lowQuality : highQuality}
            data-src={windowWidth <= 1000 ? lowQuality : highQuality}
            alt="slideshow"
            loading="lazy"
            className={style.slideshowImage}
          />
        ))}
      </div>
      <div className={style.slideshowDots}>
        {images.map((_, idx) => (
          <div
            key={idx}
            className={`${style.slideshowDot}${
              index === idx ? ' ' + style.active : ''
            }`}
            onClick={() => {
              setIndex(idx)
            }}
          ></div>
        ))}
      </div>
    </div>
  )
}

export default Slideshow
