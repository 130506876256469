const baseUrl =
  'https://api.telegram.org/bot6929268295:AAGg-JuXRpYqZK2vRbsyV0F0n2EonYWKkWU/'

const chatId = '-4134532943'
// test -4142463071
// main -4134532943

export const sendMessage = async (message: string): Promise<void> => {
  const url = `${baseUrl}sendMessage?chat_id=${chatId}&text=${message}`

  const response = await fetch(url)

  if (!response.ok) {
    const error = await response.json()

    await Promise.reject(error.Description || 'Что-то пошло не так...')
  }

  console.log('response', response)
}
