import { useState } from 'react'

const useNotifications = () => {
  const [notifications, setNotifications] = useState([])

  const newNotification = (notification) => {
    return {
      text: notification.text,
      status: notification.status ?? 'info',
      onFinish: notification.hasOwnProperty('onFinish')
        ? () => {
            notification.onFinish()
            removeNotification(notifications.indexOf(notification))
          }
        : () => {
            removeNotification(notifications.indexOf(notification))
          },
      actionText: notification.actionText ?? null,
      action: notification.hasOwnProperty('action')
        ? () => {
            notification.action()
            removeNotification(notifications.indexOf(notification))
          }
        : () => {
            removeNotification(notifications.indexOf(notification))
          },
    }
  }

  const addNotification = (notification) => {
    setNotifications((notifications) => [
      ...notifications,
      newNotification(notification),
    ])
  }

  const removeNotification = (i) => {
    const array = notifications.splice(i, 1)
    setNotifications(array)
  }

  return {
    notifications,
    addNotification,
  }
}

export default useNotifications
