import React, { useState, useRef, useEffect } from 'react'
import styles from './MusicPlayer.module.css'
import play from '../img/play.png'
import pause from '../img/pause.png'
import { trackSets } from './trackSets'
import { PlayerControls } from './PlayerControls'
import { BeatLoader } from 'react-spinners'

const MusicPlayer = ({ trackSetName, genre, reset, onReset }) => {
  const currentTrackSet = trackSets.find((set) => set.name === trackSetName)
  const tracks = currentTrackSet.tracks

  const [localCurrentTrackIndex, setLocalCurrentTrackIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [sliderValue, setSliderValue] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const [trackDurations, setTrackDurations] = useState(
    Array(tracks.length).fill(0)
  )
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true) // Добавьте состояние isLoading
  const audioRef = useRef(new Audio())
  useEffect(() => {
    const updateTrackDurations = async () => {
      setIsLoading(true) // Устанавливайте isLoading в true перед началом загрузки данных

      const context = new (window.AudioContext || window.webkitAudioContext)()

      const durations = await Promise.all(
        tracks.map(async (track) => {
          return new Promise((resolve, reject) => {
            fetch(track.src)
              .then((response) => response.arrayBuffer())
              .then((arrayBuffer) => context.decodeAudioData(arrayBuffer))
              .then((audioBuffer) => {
                resolve(audioBuffer.duration)
              })
              .catch((error) => {
                console.error('Error extracting track duration:', error)
                reject(error)
              })
          })
        })
      )

      setTrackDurations(durations)
      setIsLoaded(true)
      setIsLoading(false) // Устанавливайте isLoading в false после загрузки данных
    }

    updateTrackDurations()
  }, [tracks, reset])

  useEffect(() => {
    audioRef.current.onloadedmetadata = () => {
      setDuration(audioRef.current.duration)
    }

    const intervalId = setInterval(() => {
      setCurrentTime(audioRef.current.currentTime)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(audioRef.current.currentTime)
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  useEffect(() => {
    const handleTimeUpdate = () => {
      const currentTime = audioRef.current.currentTime
      setSliderValue(currentTime)
    }

    if (isPlaying) {
      const intervalId = setInterval(handleTimeUpdate, 1000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isPlaying])

  const playTrack = (index) => {
    if (localCurrentTrackIndex === index) {
      if (isPlaying) {
        audioRef.current.pause()
      } else {
        audioRef.current.play()
      }
      setIsPlaying(!isPlaying)
    } else {
      setLocalCurrentTrackIndex(index)
      audioRef.current.src = tracks[index].src
      audioRef.current.currentTime = 0
      audioRef.current.play()
      setIsPlaying(true)
      setIsActive(true) // Добавьте эту строку
      setSliderValue(0)
    }
  }

  const handleAudioEnded = () => {
    setIsPlaying(false)
    setLocalCurrentTrackIndex(null)
    setSliderValue(0)
  }

  const handleClose = () => {
    setIsActive(false)
  }

  const handleVolumeChange = (event) => {
    audioRef.current.volume = event.target.value
  }

  const handleTimeChange = (event) => {
    const newTime = event.target.value
    audioRef.current.currentTime = newTime
    setCurrentTime(newTime)
  }

  const formatTime = (timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) {
      return '0:00'
    }

    const minutes = Math.floor(timeInSeconds / 60)
    const seconds = Math.floor(timeInSeconds % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return (
    <div className={styles['music-player']}>
      <h2 className={styles['genre-name']}>{genre}</h2>
      {isLoading && ( // Отображайте индикатор загрузки, пока данные загружаются
        <div className={styles['loading-container']}>
          <BeatLoader color="#ffffff" loading={isLoading} />
        </div>
      )}
      {isLoaded && !isLoading && (
        <ul className={styles['music-list']}>
          {tracks.map((track, index) => (
            <li key={index} className={styles['music-item']}>
              <button
                onClick={() => playTrack(index)}
                className={styles['music-button']}
              >
                {localCurrentTrackIndex === index && isPlaying ? (
                  <img src={pause} alt="Pause" />
                ) : (
                  <img src={play} alt="Play" />
                )}
              </button>
              <div className={styles['audio-info']}>
                <div className={styles['audio-name']}>
                  <span className={styles['audio-artist']}>
                    {track.artist}{' '}
                  </span>{' '}
                  <span className={styles['audio-defis']}>-</span>
                  <span className={styles['audio-title']}>{track.title} </span>
                </div>

                <span className={styles['audio-duration']}>
                  {formatTime(
                    trackDurations[index],
                    isPlaying
                      ? trackDurations[index] - Math.floor(sliderValue)
                      : 0
                  )}
                </span>
              </div>
            </li>
          ))}
        </ul>
      )}
      <audio ref={audioRef} onEnded={handleAudioEnded} />
      {isActive && (
        <PlayerControls
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          playTrack={playTrack}
          audioRef={audioRef}
          onVolumeChange={handleVolumeChange}
          onTimeChange={handleTimeChange}
          currentTime={currentTime}
          duration={duration}
          onClose={handleClose}
        />
      )}
    </div>
  )
}

export default MusicPlayer
